import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
};
export const createNotification = (values) => async (dispatch) => {
  try {
    const response = await axiosApi.post(`admin/notification/custome/sendNotificationAllUser`, values);
    console.log("Values ",values);
    if (response) {
      toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    toast.error(error.response?.data?.message);
  }
};

export const notificationListApi = (data) => async (dispatch) => {
  try {
    dispatch(notificationSetState([{ key: "isLoading", value: true }]));
    var response;
    if (data?.search != "") {
      response = await axiosApi.get(
        `admin/notification/custome/all?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
        null
      );
    } else {
      response = await axiosApi.get(
        `admin/notification/custome/all?page=${data?.page}&limit=${data?.limit}`,
        null
      );
    }
    if (response) {
      console.log(response?.data?.data);
      dispatch(
        notificationSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "notificationListData", value: response?.data}, // Change this
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(notificationSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

export const updateNotificationAPi =
  ({ notificationId, values }) =>
  async (dispatch) => {
    try {
      dispatch(notificationSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.put(`admin/notification/custome/update/${notificationId}`, values);
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          notificationSetState([
            { key: "isLoading", value: false },
            { key: "updateContestData", value: response?.data?.message },  // Change this
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(notificationSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

//** Delete Contest **\\
export const deleteNotificationApi =
  ({ notificationId, setDeleteContestModal }) =>
  async (dispatch) => {
    try {
      dispatch(
        notificationSetState([
          { key: "isLoading", value: true },
          { key: "deleteNotificationData", value: null },
        ])
      );

      const response = await axiosApi.delete(
        `admin/notification/custome/delete/${notificationId}`,
        null
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          notificationSetState([
            { key: "isError", value: null },
            { key: "isLoading", value: false },
            { key: "deleteNotificationData", value: response?.data?.message },  // Change this
          ])
        );
        setDeleteContestModal(false);
      }
      return response;
    } catch (error) {
      dispatch(notificationSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };


  const notificationSlice = createSlice({
    name: "notification",
    initialState: initialStates,
    reducers: {
      notificationSetState: (state, { payload }) => {
        if (Array.isArray(payload)) {
          for (const obj of payload) {
            _.set(state, obj.key, obj.value);
          }
        } else {
          _.set(state, payload.key, payload.value);
        }
      },
    },
  });
  
  export const { notificationSetState } = notificationSlice.actions;
  
  const { reducer } = notificationSlice;
  
  export default reducer;
