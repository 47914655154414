import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
};

//** Scratch Card List **\\
export const scratchCardListApi = (data) => async (dispatch) => {
  try {
    dispatch(scratchSetState([{ key: "isLoading", value: true }]));
    var response;
    if (data?.search != "") {
      response = await axiosApi.get(
        `admin/scratchcard/viewAll?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
        null
      );
    } else {
      response = await axiosApi.get(
        `admin/scratchcard/viewAll?page=${data?.page}&limit=${data?.limit}`,
        null
      );
    }
    if (response) {
      // console.log(response.data);
      dispatch(
        scratchSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "scratchCardListData", value: response?.data }, // Change this
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(scratchSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

//** Create ScratchCard **\\
export const createScratchCardAPi = (values) => async (dispatch) => {
  try {
    dispatch(scratchSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.post(`admin/scratchcard/create`, values);
    if (response) {
      toast.success(response?.data?.message);
      dispatch(
        scratchSetState([
          { key: "isLoading", value: false },
          { key: "createContestData", value: response?.data?.message },  // Change this
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(scratchSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

//** Update Contest **\\
export const updateScratchCardAPi =
  ({ scratchCardId, values }) =>
  async (dispatch) => {
    try {
      dispatch(scratchSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.put(`admin/scratchcard/update/${scratchCardId}`, values);
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          scratchSetState([
            { key: "isLoading", value: false },
            { key: "updateContestData", value: response?.data?.message },  // Change this
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(scratchSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

//** Delete Contest **\\
export const deleteScratchCardApi =
  ({ scratchId, setDeleteContestModal }) =>
  async (dispatch) => {
    try {
      dispatch(
        scratchSetState([
          { key: "isLoading", value: true },
          { key: "deleteScratchCardData", value: null },
        ])
      );
      const response = await axiosApi.delete(
        `admin/scratchcard/delete/${scratchId}`,
        null
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          scratchSetState([
            { key: "isError", value: null },
            { key: "isLoading", value: false },
            { key: "deleteScratchCardData", value: response?.data?.message },  // Change this
          ])
        );
        setDeleteContestModal(false);
      }
      return response;
    } catch (error) {
      dispatch(scratchSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };


// const initialStates = {
//   isError: null,
//   isLoading: false,
//   maxRankingsData: null,
//   currentRankingsData: null,
// };

const scratchSlice = createSlice({
  name: "scratchcard",
  initialState: initialStates,
  reducers: {
    scratchSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { scratchSetState } = scratchSlice.actions;

const { reducer } = scratchSlice;

export default reducer;
